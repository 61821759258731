const images = document.querySelectorAll("[data-src]");
const config = {
  //root:0,
  rootMargin: "400px 0px 500px 0px",
  threshold: .1,
};

let observer = new IntersectionObserver(function (entries, self) {
  
  entries.forEach(entry => {

    if(entry.isIntersecting) {

      preloadImage(entry.target)
      self.unobserve(entry.target)
    }
  })
}, config);

images.forEach(image => {
  observer.observe(image);
})

function preloadImage(img) {
  const src = img.dataset.src

  if(!src) return

  img.setAttribute('src', src);
  img.removeAttribute('[data-src]')
/*   img.parentElement.classList.add('is-shown'); */

}
