import * as utils from "../utils/utils";

export function setAnimAccueil() {
    if (document.querySelector(".intro")) {

      const img = document.querySelector(".intro .intro__card__img img");
      const bigLogo = document.querySelectorAll(".intro .logo-big .icon *");
      const libelles = document.querySelector(".intro .libelles");
      const sTitle = document.querySelector(".intro .s-title5");
      const liensAncre = document.querySelectorAll(".intro .ancres .ancres-item");
      const applat = document.querySelector(".intro .js-applat-anim");
    /*   const imgCard = document.querySelector(".intro .intro__card__img"); */
      const mask = document.querySelector('.intro .mask')
      const infosCard = document.querySelectorAll(".intro .intro__card__body > *");
      const overlayCard = document.querySelector(".intro .overlay");

      const delaySTitle5 = 150;
      const delayMaskWhite = 200;
      const delayMaskRed = 400;
      const delayBlocTitre = 400;
      const delayAncres = 500;

      img.addEventListener('load', lanceAnim())

      function lanceAnim() {

        bigLogo.forEach((path,i) => {
          window.setTimeout(() => {
            utils.addClass(path, 'is-active')
          },i*20)
        })

        if(libelles) {
          window.setTimeout(() => {
            utils.addClass(libelles, 'is-active')
          },delaySTitle5)
        }

        if(sTitle) {
          window.setTimeout(() => {
            utils.addClass(sTitle, 'is-active')
          },delaySTitle5)
        }      
  
        window.setTimeout(() => {
          utils.addClass(mask, 'is-active')
        },delayMaskWhite) // .33s
  
        window.setTimeout(() => {
          utils.addClass(applat, 'is-active');
        },delayMaskRed) // .33s
        
        window.setTimeout(() => {

        infosCard.forEach((elt,i) => {

          window.setTimeout(() => {
            utils.addClass(elt, 'is-active')
          },i*30)
        })
        },delayBlocTitre) // .33s

        window.setTimeout(() => {
          utils.addClass(overlayCard, 'is-active');
        },delayBlocTitre) // .33s

    
        window.setTimeout(() => {

          liensAncre.forEach((elt,i) => {
  
            window.setTimeout(() => {
              utils.addClass(elt, 'is-active')
            },i*30)
          })
  
          },delayAncres) // .33s
  
      }
    }
  }