import { setAnimAccueil } from "./modules/animAccueil";

import * as utils from "./utils/utils";
import * as globals from "./utils/globals";
import * as Flickity from "flickity";
import { gsap } from "gsap";
import "./modules/Lazyload";
import Carousel from "./modules/Carousel";


if (globals.isMobile) {
  utils.addClass(document.documentElement, "is-mobile");
}
if (globals.ms_ie) {
  utils.addClass(document.documentElement, "is-ie");
  // utils.addClass( document.documentElement, 'no-js');
}
if (globals.isTouch) {
  utils.removeClass(document.documentElement, "no-touchevents");
  utils.addClass(document.documentElement, "touchevents");
}

window.onload = (event) => {
  const $style = document.getElementById("main-css");

  if ($style.isLoaded) {
    init();
  } else {
    $style.addEventListener("load", (event) => {
      init();
    });
  }
};

function init() {
  setTimeout(() => {
    utils.addClass(document.documentElement, "is-loaded");
    utils.addClass(document.documentElement, "is-ready");
    utils.removeClass(document.documentElement, "is-loading");
  }, 250);

  utils.set_vh();

  // Polyfill object fit images
  if (typeof objectFitImages !== "undefined") {
    objectFitImages(".imageFit img, .imageFit video");
  }

  const gridMultipleCards = document.querySelectorAll(".grid-multiple-cards");
  if (gridMultipleCards && window.innerWidth >= 768) {
    addMarginColonne();
  }

  window.addEventListener("resize", (e) => {
    addMarginColonne(e.target.innerWidth);
    /*  fixHauteurSommaire(); */
  });

  setAnimAccueil();
  gestionsBandeaux();
  openSommaire();
  linkExpand();
  accordion();
  activeLangSwitcher();
  setAnimTiretMenu();
  fermeBandeau();
  filters();
  menuAccueilMobile();

  function menuAccueilMobile() {
    const burger = document.querySelector(".menu-accueil__hamburger");

    if (burger) {
      if (window.innerWidth <= 768) {
        const menuAccueil = document.querySelector(".menu-accueil");
        const ancres = document.querySelectorAll(".menu-accueil .ancres-item");
        const reseauSociaux = document.querySelector(
          ".menu-accueil .reseaux-sociaux"
        );

        burger.addEventListener("click", (e) => {
          document.body.classList.toggle("noScroll");
          menuAccueil.classList.toggle("is-active");

          window.setTimeout(() => {
            ancres.forEach((ancre, i) => {
              window.setTimeout(() => {
                if (menuAccueil.classList.contains("is-active")) {
                  ancre.style.opacity = 1;
                  ancre.style.transform = "translateY(0)";
                } else {
                  ancre.style.opacity = 0;
                  ancre.style.transform = "translateY(20px)";
                }
              }, i * 80);
            });
          }, 450);
        });
      }
    }
  }

  function setAnimTiretMenu() {
    if (globals.isMobile && document.body.classList.contains("home")) {
      const tabAncres = [];
      const listSections = [];
      const liensMenu = document.querySelectorAll(".menu-accueil li a");
      const tiret = document.querySelector(".menu-accueil .tiret");
      const config = {
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.2,
      };

      // On récupère les dataset (ancres) dans les liens du menu
      liensMenu.forEach((elt) => tabAncres.push(elt.dataset.ancre));

      // On récupère les sections correspondantes aux ancres
      tabAncres.forEach((ancre) => {
        if (ancre) {
          listSections.push(document.getElementById(ancre));
        }
      });

      // On crée l'observateur
      const observerSection = new IntersectionObserver((entries, self) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimTiret(entry.target);
          }
        });
      }, config);

      // On observe
      listSections.forEach((section) => {
        observerSection.observe(section);
      });

      function setAnimTiret(section) {
        const id = section.id;
        const lienCible = Array.from(liensMenu).filter((lien) => {
          return lien.dataset.ancre === id;
        });

        animTiret(tiret, lienCible[0]);
      }
    }
  }

  function animTiret(cible, lien) {
    gsap.to(cible, {
      opacity: 1,
      backgroundColor: lien.dataset.color,
      left: () => {
        return lien.offsetParent.nodeName === "LI"
          ? lien.offsetParent.offsetLeft
          : lien.offsetLeft;
      },
      width: lien.offsetWidth,
      ease: "back.out(1.4)",
    });
  }

  function activeLangSwitcher() {
    const dropdown =
      document.querySelector(".bandeau-kiosque .lang-switcher .dropdown") ||
      false;

    if (!dropdown) return;

    dropdown.addEventListener("click", (e) => {
      utils.toggleClass(dropdown, "is-active");
    });
  }

  function addMarginColonne(lgWindow = 900) {
    gridMultipleCards.forEach((elt) => {
      const colonnes = elt.querySelectorAll(".colonne:not(:first-child)");

      if (elt.querySelector(".section-name")) {
        const hauteurSectionName =
          elt.querySelector(".section-name").clientHeight;

        colonnes.forEach((colonne) => {
          if (lgWindow >= 768) {
            colonne.style.top = hauteurSectionName + "px";

            // On compense par un marge basse pour avoir la bonne hauteur du conteneur
            colonne.style.marginBottom = hauteurSectionName + 20 + "px";
          } else {
            colonne.style.top = 0 + "px";
            colonne.style.marginBottom = 0 + "px";
          }
        });
      }
    });
  }

  function gestionsBandeaux() {
    const menuAccueilElt = document.querySelector(".menu-accueil") || false;
    const bandeauGris = document.querySelector(".bandeau-kiosque") || false;
    let hauteurIntro = 0;
    let lastScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;

    const hauteurApparitionLogo = window.innerWidth <= 768 ? 4.5 : 1.8;

    if (menuAccueilElt && bandeauGris) {
      const elt = document.querySelector(".home .intro") || false;

      hauteurIntro = !elt ? 0 : elt.clientHeight;

      // Bandeau kiosque apparait dès le début sur mobile
      if (globals.isMobile) {
        utils.addClass(bandeauGris, "is-shown");
      }

      window.addEventListener("scroll", (e) => {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (!globals.isMobile) {
          if (window.scrollY >= hauteurIntro) {
            if (st > lastScrollTop) {
              // Scroll vers le bas
              utils.removeClass(menuAccueilElt, "is-shown");
            } else {
              // Scroll vers le haut
              utils.addClass(menuAccueilElt, "is-shown");
            }
          }
        }

        if (menuAccueilElt) {
          if (window.scrollY >= hauteurIntro / hauteurApparitionLogo) {
            menuAccueilElt.classList.add("has-elements");
          } else {
            menuAccueilElt.classList.remove("has-elements");
          }
        }

        if (globals.isMobile) {
          // Scroll vers le bas
          if (st > lastScrollTop) {
            utils.removeClass(bandeauGris, "is-shown");
            utils.removeClass(menuAccueilElt, "is-shown");
          }

          // Scroll vers le haut
          else {
            utils.addClass(bandeauGris, "is-shown");
            utils.addClass(menuAccueilElt, "is-shown");
          }
        }

        lastScrollTop = st <= 0 ? 0 : st; // Pour mobile ou scroll négatif
      });
    }
  }

  function openSommaire() {
    const btnOpenSommaire = document.querySelector(".js-sommaire") || false;

    if (!btnOpenSommaire) return;

    const sommaire = document.querySelector(".sommaire.js-modal");
    const sommaireNumeros =
      document.querySelectorAll(".sommaire .numero") || false;
    const eltsBlocG =
      document.querySelectorAll(".sommaire .sommaire__bloc-g > *") || false;
    const eltsBlocD =
      document.querySelectorAll(".sommaire .sommaire__section > *") || false;
    const btnClose = document.querySelector(".sommaire .js-btn-close");

    btnOpenSommaire.addEventListener("mouseover", () => {
      const imgsSommaire = sommaire.querySelectorAll("img [data-src]");

      imgsSommaire.forEach((img) => {
        let url = img.getAttribute("data-src");
        img.setAttribute("src", url);
      });
    });

    btnOpenSommaire.addEventListener("click", (e) => {
      e.preventDefault();
      utils.addClass(document.body, "noScroll");

      const delayBlockG = 700;
      const delayBlockD = 500;
      const delayCroix = 600;

      // display block du sommaire
      utils.addClass(sommaire, "is-shown");

      // Apparation du sommaire
      window.setTimeout(() => {
        utils.addClass(sommaire, "is-active");
      }, 10);

      if (sommaireNumeros) {
        // Apparation des éléments numéros
        window.setTimeout(() => {
          sommaireNumeros.forEach((elt, i) => {
            window.setTimeout(() => {
              utils.addClass(elt, "is-active");
            }, i * 50);
          });
        }, delayBlockG);
      }

      if (eltsBlocG) {
        window.setTimeout(() => {
          eltsBlocG.forEach((elt, i) => {
            window.setTimeout(() => {
              utils.addClass(elt, "is-active");
            }, i * 50);
          });
        }, delayBlockG);
      }

      if (eltsBlocD) {
        // Apparation des éléments du bloc D
        window.setTimeout(() => {
          eltsBlocD.forEach((elt, i) => {
            window.setTimeout(() => {
              utils.addClass(elt, "is-active");
            }, i * 50);
          });
        }, delayBlockD);
      }

      // Apparation de la croix
      window.setTimeout(() => {
        utils.addClass(btnClose, "is-active");
      }, delayCroix);

      /*  fixHauteurSommaire();
       */
      const btnCloseSommaire = document.querySelector(".js-btn-close");

      btnCloseSommaire.addEventListener("click", () => {
        if (sommaireNumeros) {
          sommaireNumeros.forEach((elt, i) => {
            utils.addClass(elt, "collapse");
          });
        }

        if (eltsBlocD) {
          eltsBlocD.forEach((elt, i) => {
            utils.addClass(elt, "collapse");
          });
        }

        if (eltsBlocG) {
          eltsBlocG.forEach((elt, i) => {
            utils.addClass(elt, "collapse");
          });
        }

        utils.addClass(btnClose, "collapse");

        window.setTimeout(() => {
          utils.removeClass(sommaire, "is-active");
        }, 300);

        window.setTimeout(() => {
          utils.removeClass(sommaire, "is-shown");

          if (sommaireNumeros) {
            sommaireNumeros.forEach((elt, i) => {
              utils.removeClass(elt, "collapse");
              utils.removeClass(elt, "is-active");
            });
          }

          if (eltsBlocD) {
            eltsBlocD.forEach((elt, i) => {
              utils.removeClass(elt, "collapse");
              utils.removeClass(elt, "is-active");
            });
          }

          if (eltsBlocG) {
            eltsBlocG.forEach((elt, i) => {
              utils.removeClass(elt, "collapse");
              utils.removeClass(elt, "is-active");
            });
          }

          utils.removeClass(btnClose, "collapse");
          utils.removeClass(btnClose, "is-active");

          utils.removeClass(sommaire, "is-active");

          utils.removeClass(document.body, "noScroll");
        }, 1000);
      });
    });
  }
  /* 
  function fixHauteurSommaire() {
    let blocAMesurer = document.querySelector(".js-sommaire-bd") || false;
    let hauteur = 0;

    if (blocAMesurer) {
      if (window.innerWidth >= 768) {
        hauteur = window.innerHeight;
        blocAMesurer.style.height = hauteur + "px";
      } else {
        blocAMesurer.style.height = "unset";
        blocAMesurer =
          document.querySelector(".js-modal .sommaire__wrapper .grid") || false;

        hauteur = window.innerHeight;
        blocAMesurer.style.height = hauteur + "px";
      }
    }
  } */

  function linkExpand() {
    const lienAEtendre =
      document.querySelectorAll("[data-link-target]") || false;

    if (lienAEtendre) {
      lienAEtendre.forEach((lien) => {
        const blocAActiver = lien.closest("[data-link-expand]");

        lien.addEventListener("mouseover", () => {
          utils.addClass(blocAActiver, "is-active");
        });

        lien.addEventListener("mouseout", () => {
          utils.removeClass(blocAActiver, "is-active");
        });
      });
    }
  }

  function accordion() {
    const buttons = document.querySelectorAll(".js-btn-accordion") || false;

    if (!buttons) return;

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const blocAccordion = button
          .closest(".has-accordion")
          .querySelector(".accordion-panel");

        // scrollHeight récupère la hauteur d'un élément même avec un overflow
        const hauteurBlocAccordion = blocAccordion.scrollHeight + "px";

        if (blocAccordion.style.maxHeight) blocAccordion.style.maxHeight = null;
        else blocAccordion.style.maxHeight = hauteurBlocAccordion;

        utils.toggleClass(button, "is-active");
      });
    });
  }

  function fermeBandeau() {
    const menuAccueilLiens =
      document.querySelectorAll(".menu-accueil .ancres .ancres-item a") ||
      false;

    if (menuAccueilLiens) {
      const bandeau = document.querySelector(".menu-accueil");

      menuAccueilLiens.forEach((lien) => {
        lien.addEventListener("click", (e) => {
          window.setTimeout(() => {
            utils.removeClass(bandeau, "is-shown");
          }, 50);
        });
      });
    }
  }

  const carousels = document.querySelectorAll(".section-carousel .carousel");

  if (carousels) {
    carousels.forEach((carousel) => {
      new Carousel(carousel);
    });
  }

  function filters() {
    const filters = document.querySelector(".js-filters");

    if (filters) {
      const rubrique = filters.dataset.rubrique;

      const filtersBtn = document.querySelectorAll(".js-filters button");
      const submit = document.querySelector(".js-form-filters [type=submit]");
      const inputRubrique = document.querySelector(
        ".js-form-filters #rubrique"
      );
      const inputCategorie = document.querySelector(
        ".js-form-filters #categorie"
      );
      let categorieName = "";
      const hash = document.querySelector(
        ".page-liste .grid-multiple-cards"
      ).id;

      let search = window.location.search;

      window.location.hash = search !== "" ? "#" + hash : "";

      filtersBtn.forEach((categorie) => {
        categorie.addEventListener("click", (e) => {
          categorieName = categorie.dataset.categorie;

          inputRubrique.value = rubrique;
          inputCategorie.value = categorieName;

          submit.click();
        });
      });
    }
  }

  activeWidgetFull();

  function activeWidgetFull() {
    const buttonActivation = document.querySelector(".js-active-full-widget");

    if (buttonActivation) {
      
      const widgetFull = document.querySelector(".js-widget-full");

      if (widgetFull) {

        buttonActivation.addEventListener('click', (e) => {

          const widgetElts = widgetFull.querySelectorAll('section');
          const buttonClose = widgetFull.querySelector(".js-close-widget");

          document.body.classList.add("noScroll");
          widgetFull.classList.add("is-active");
         

          // Transition des sections
          window.setTimeout(() => {
            widgetElts.forEach((elt, i) => {
              window.setTimeout(() => {
                utils.addClass(elt, "is-active");
              }, i * 50);
            });
          }, 200);
          
  
          // Lance le carousel d'images
          let flkty = new Flickity(".js-carousel-widget", {
            cellAlign: "left",
            contain: true,
            imagesLoaded: true,
            lazyLoad: 2,
            prevNextButtons: false,

          });
  
          // Close widget button
          buttonClose.addEventListener("click", () => {
            widgetFull.classList.remove("is-active");
            widgetElts.forEach((elt, i) => {
              elt.classList.remove('is-active')
            });
            document.body.classList.remove("noScroll");
          });

          // Close widget body
          window.onclick = function(event) {
            if (event.target == widgetFull) {
              widgetFull.classList.remove('is-active')
              document.body.classList.remove("noScroll");
            }
          }
        })
      }
    }
  }

  activeGridHelp();
  function activeGridHelp() {
    let grid = document.querySelector(".grid.layer");

    if (grid) {
      let code = [],
        i = 0;

      window.addEventListener("keypress", (e) => {
        if (i === 2) {
          i = 0;
          code = [];
        }
        i++;
        code.push(e.key);

        if (code.join() === "g,g") {
          if (grid.classList.contains("is-active")) {
            grid.style.display = "none";
            grid.classList.remove("is-active");
          } else {
            grid.style.display = "grid";
            grid.classList.add("is-active");
          }
        }
      });
    }
  }
}
