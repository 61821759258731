import * as Flickity from "flickity";
import * as utils from "../utils/utils";

export default class Carousel {
  constructor(elt) {
    (this.elt = elt),
      (this.params = {
        initialIndex: 0,
        cellAlign: "left",
        pageDots: false,
        prevNextButtons: false,
        contain: false,
       
      }),
      (this.slider = null),
      (this.buttonPrev =
        this.elt.nextElementSibling.querySelector(".js-previous")),
      (this.buttonNext = this.elt.nextElementSibling.querySelector(".js-next")),
      (this.lastIndex = 0),
  
        this.length = this.elt.childElementCount,

    this.btnsPopin =
      this.elt.querySelectorAll(".carousel-cell .js-close") || false;

    this.init();
  }

  init() {
 
    if( this.length < 2 ){ return; }

    this.slider = new Flickity(this.elt, this.params);
    this.sliderData = Flickity.data( this.elt );

    this.initEvents();
  }

  initEvents() {
    this.slider.on("select", (index) => {
      // Next
      if (index > this.lastIndex) {
        let cellsToMove = utils.range(this.lastIndex, index, 1);

        cellsToMove.pop(); // remove last item
    
        for (let i = 0; i < cellsToMove.length; i++) {
          this.nextTransition(this.slider.cells[cellsToMove[i]]);
        }

        // Prev
      } else if (index < this.lastIndex) {
        let cellsToMove = utils.range(index, this.lastIndex, 1);

        cellsToMove.pop(); // remove last item

        for (let i = 0; i < cellsToMove.length; i++) {
          this.prevTransition(this.slider.cells[cellsToMove[i]]);
        }
      }
      
      this.lastIndex = index;
    });

    this.buttonPrev.addEventListener("click", () => {
      this.prev();
    });

    this.buttonNext.addEventListener("click", () => {
      this.next();
    });

    this.btnsPopin.forEach((btn) => {
      btn.addEventListener("click", () => {
        utils.toggleClass(btn.parentElement, "is-shown");
      });
    });
     // Navigation disabled selon slide sélectionnée
     if( this.elt.nextElementSibling.classList.contains('js-carousel-btns') ){
     
        this.slider.on( 'select', (index)=>{

            if(this.sliderData.slides.length == 1){
                this.elt.nextElementSibling.querySelector('.button-previous').setAttribute('disabled', '');
                this.elt.nextElementSibling.querySelector('.button-next').setAttribute('disabled', '');

                return;
            }

            if( this.sliderData.selectedIndex == 0 ){
                this.elt.nextElementSibling.querySelector('.button-previous').setAttribute('disabled', '');
                this.elt.nextElementSibling.querySelector('.button-next').removeAttribute('disabled');
            } else if( this.sliderData.selectedIndex == (this.sliderData.slides.length - 1) ){
                this.elt.nextElementSibling.querySelector('.button-previous').removeAttribute('disabled');
                this.elt.nextElementSibling.querySelector('.button-next').setAttribute('disabled', '');
            } else {
                this.elt.nextElementSibling.querySelector('.button-previous').removeAttribute('disabled');
                this.elt.nextElementSibling.querySelector('.button-next').removeAttribute('disabled');
            }
        });
      
  }
  }

  prev() {
    this.slider.previous();
  }

  next() {
    this.slider.next();
  }

  prevTransition(cell) {
    utils.removeClass(cell.element, "out");
    utils.addClass(cell.element, "in");
    cell.element.style.transform = "translate3d( 0, 0, 0)";
  }

  nextTransition(cell) {
    utils.removeClass(cell.element, "in");
    utils.addClass(cell.element, "out");
    cell.element.style.transform = "translate3d( -50vw, 0, 0)";
  }
}
